import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom';
import { confirmAlert } from '../utils/react-confirm-alert';
import { validateEmail, getURLParameter, sanitizeEmail } from '../utils/HelpfulFunction';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png';
import posed from 'react-pose';

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
          redirect: false,
          isVisible: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          inputtedPhoneNumber: '',
          userEmail: sessionStorage.getItem('userEmail') || false,
        };
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageErrored = this.handleImageErrored.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        isVisible: true
      }), 1000);
    }

    handleImageErrored() {
      alert(this.props.stringConstants.FAILEDTOLOADTEXT)
      this.setState({ loading: false });
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          let htmlEmailParameter = getURLParameter("email");
          if(htmlEmailParameter && key){
            let base64EncodedEmail = btoa(htmlEmailParameter);
            base.fetch('userGameHistory/'+ base64EncodedEmail+"/"+key, {
              context: this,
              then(data){
                if(typeof data === 'string'){
                  this.props.setPassed(true);
                  this.continueSignInProcess(htmlEmailParameter, "", "", false,"")
                }
              }
            })
          }
        }
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.props.loadPage();
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    skipLogin(){
      const tenantVariables = this.state.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.ERRORTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      const randomElement = Math.floor((Math.random() * 100000) + 1);
      const sessionTokenString = Date.now().toString() + randomElement.toString();
      sessionStorage.setItem("tempToken", sessionTokenString);
      this.setState({
        redirect: true,
      })
    }

    toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleDateChange(event) {
    let value = event.target.value;
    if(event.target.name === "month"){
      if(value > 12){
        value = "12"
      }
    } else if(event.target.name === "day"){
      if(value > 31){
        value = "31"
      }
    } else if(event.target.name === "year"){
      if(value > new Date().getFullYear()){
        value = new Date().getFullYear().toString()
      }
    }
    this.setState({[event.target.name]: value});
  }

    isValidDate(s) {
      const bits = s.split('-');
      const d = new Date(bits[0], bits[1] - 1, bits[2]);
      return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    handleSubmit(event){
      event.preventDefault();
      const stringConstants = this.props.stringConstants || {};
      const inputtedEmail = this.state.inputtedEmail.trim();
      let htmlParameterEmail = getURLParameter("email");
      const tenantVariables = this.props.variables;
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const inputtedName = this.state.inputtedName.trim();
      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;
      let inputtedBirthday = sessionStorage.getItem('birthday') || "";
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const inputtedPhoneNumber = this.state.inputtedPhoneNumber.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const noCollectingEmails = tenantVariables.doNotCollectEmail || isMlbApp;
      if(inputtedEmail === "" && !htmlParameterEmail && !noCollectingEmails){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLEMAILERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && !validateEmail(inputtedEmail) && !htmlParameterEmail){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.UNABLETOREADEMAILTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && (tenantVariables.allowList || tenantVariables.blockList)){
        const lowerCaseEmail = inputtedEmail.toLowerCase();
        const emailDomain = lowerCaseEmail.split('@')[1];
        let allowListArray = [];
        let blockListArray = [];
        if(tenantVariables.allowList){
          allowListArray = tenantVariables.allowList.split(" ");
        }
        if(tenantVariables.blockList){
          blockListArray = tenantVariables.blockList.split(" ");
        }
        if(tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        } else if(tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
          console.log("Allow override")
        } else if(tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      }

      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLBIRTHDAYERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday){
        if(year.length < 4){
          confirmAlert({
            variables: this.props.variables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.BIRTHDAYYEARBADFORMAT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
        if(month && month.length < 2){
          month = "0" + month
        }
        if(day && day.length < 2){
          day = "0" + day
        }
        inputtedBirthday = year + "-" + month + "-" + day;
        if(isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)){
          confirmAlert({
            variables: this.props.variables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.INVALIDDATEERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: this.props.variables,
            title: stringConstants.INPUTERRORTITLE,
            message: stringConstants.NOTOLDENOUGHERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      }

      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectName && (inputtedName === "" || inputtedName.split(' ').length <= 1 || inputtedName.length > 35)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOLASTNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber === ""){
        if(!tenantVariables.optionalPhoneNumber){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.EMPTYPHONENUMBERTEXT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      } else if(tenantVariables.collectPhoneNumber && !/^\d+$/.test(inputtedPhoneNumber)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber.length !== 10){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLZIPCODEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputtedZipCode)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTVALIDUSZIP,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }

      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.ERRORTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(noCollectingEmails){
        this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber)
        return
      }
      if(!htmlParameterEmail) {
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.CONFIRMEMAILTEXT,
          message: inputtedEmail,
          buttons: [
            {
              label: stringConstants.NODISMISSBUTTONTEXT,
            },
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: async () => {
                this.setState({
                  loading: true
                })
                this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber)
              }
            }
          ]
        })
      } else {
        this.props.setPassed(true);
        this.createUser(htmlParameterEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday);
      }
    }

    createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      let userId = getURLParameter("userid");
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      let sanitizedEmail = "";
      if(inputtedEmail){
        try {
          sanitizedEmail = sanitizeEmail(inputtedEmail);
        } catch (e) {
          console.log(e)
        }
        base64EncodedEmail = btoa(inputtedEmail);
      } else if(userId){
        base64EncodedEmail = userId;
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['sanitizedEmail'] = sanitizedEmail;
      userObject['uid'] = base64EncodedEmail;
      if(inputtedZipCode){
        userObject['zipCode'] = inputtedZipCode;
      }
      if(inputtedName){
        userObject['name'] = inputtedName;
      }
      userObject['optIn'] = agree_to_promotionRef;
      if(inputtedBirthday){
        userObject['birthday'] = inputtedBirthday;
      }
      if(inputtedPhoneNumber){
        userObject['phoneNumber'] = inputtedPhoneNumber;
      }
      if(tenantVariables.sanitizeEmails){
        inputtedEmail = sanitizedEmail;
        base64EncodedEmail = btoa(sanitizedEmail)
        userObject['uid'] = base64EncodedEmail;
      }
      const vm = this;
      base.update('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            try {
              if(inputtedEmail){
                sessionStorage.setItem('userEmail', inputtedEmail);
              } else {
                sessionStorage.setItem('userEmail', base64EncodedEmail);
              }
              vm.props.setCurrentUser();
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert(stringConstants.UNABLETOLOGYOUINTEXT)
            }

          } else {
            alert(stringConstants.UNKNOWNERRORTEXT)
          }
        }
      })
      if(base64EncodedEmail) {
        appDatabasePrimaryFunctions.ref('users/' + base64EncodedEmail + '/signUpTime').set(new Date().getTime())
            .catch(function (error) {
              console.log(error)
            })
      }
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    handleBirthdayFocus(){
      this.setState({
        birthdayFocused: true
      }, ()=> {
        document.getElementById('year').placeholder ="YYYY"
        document.getElementById('day').placeholder = "DD"
        document.getElementById('month').placeholder = "MM"
      })
    }

    render() {
      const { redirect } = this.state;
      const stringConstants = this.props.stringConstants || {};
      const tenantVariables = this.props.variables || {};
      let htmlParameterEmail = getURLParameter("email");
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const rulesRegsText = tenantRules.rulesAndRegsText || "Please agree to the Rules and Regulations";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const letsPlayButtonText = tenantVariables.letsPlayButtonText || stringConstants.LETSPLAYTEXT;
      if(!verifiedAge){
        let urlToGo = "/age_gate"
        let langague = "/"
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2];
          urlToGo += langague;
        }
        return (
            <Redirect to={urlToGo}  />
        )
      }
      if (redirect || this.state.userEmail) {
        let redirectString = "/"
        let langague = ""
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2]
          redirectString += langague
        }
        this.props.setCurrentUser();
        return (
            <Redirect to={redirectString} />
        )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      if(howToPlayText === "<p><br></p>"){
        howToPlayText = "";
      }
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="intro-container-home">
                <div className="hero-text-container">
                  <img src={frontLogoImage} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail || tenantVariables.doNotCollectEmail ? "none":""}}>{stringConstants.ENTEREMAILTEXT}</h4>
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: htmlParameterEmail || tenantVariables.doNotCollectEmail ? "none":""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDEREMAILTEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERNAMETEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectPhoneNumber ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="phoneNumber" name="inputtedPhoneNumber" type="tel" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERPHONETEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERZIPCODETEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                        <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder="Birthday" maxLength="2" pattern="\d*"/>
                        {this.state.birthdayFocused &&
                            <>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                            </>
                        }
                      </div>
                      <div style={{display:!tenantVariables.noMandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link && !rulesPopUpText?window.open(link, '_blank'):this.toggleRules()}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!tenantVariables.notDefaultCheckedOptIn && tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeToPromotionId">{promotionText}</label>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>{letsPlayButtonText}</strong></button>
                    </form>
                    {/*<div style={{display: tenantVariables.allowAnonymousLogin ? "" : "none"}}>*/}
                    {/*  <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="skipButton" onClick={()=>this.skipLogin()}><strong>{stringConstants.SKIPTEXT}</strong></button>*/}
                    {/*</div>*/}
                    <button style={{display: showHowToPlayButton?"":"none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>{stringConstants.HOWTOPLAYTEXT}</strong></button>
                  </div>
                </div>
              </Box>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.OKTEXT}</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                  {rulesPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Login;
