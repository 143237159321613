import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import { unmountComponentAtNode } from "react-dom";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import { validateEmail } from '../utils/HelpfulFunction';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png';
import sampleAppTopImage from '../../styles/images/top_icon.png';
import AnswerItemsComponent from '../../components/small_components/AnswerItemsComponent';
import { getDistance } from 'geolib';
import posed, {PoseGroup} from "react-pose";

const Trans = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: sessionStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false,
          alerted: false,
          answerList: [],
          gameActive: false,
          question: 0,
          questionAnswered: false,
          userAnswers: [],
          currentGameResponses: [],
          currentGame: {},
          voteSession: false,
          locationChecking: true,
        };
        this.logout = this.logout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }

      this.allResponsesRef = base.bindToState('userAnswers', {
        context: this,
        state: 'userAnswers',
        asArray: true,
      });

      this.gameActiveRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'gameActive',
      });

      this.questionAnsweredRef = base.bindToState('currentGame/answered', {
        context: this,
        state: 'questionAnswered',
      });

      this.questionsRef = base.bindToState('currentGame/questions', {
        context: this,
        state: 'questions',
      });

      this.allowMultipleVotesRef = base.bindToState('currentGame/allowMultipleVotes', {
        context: this,
        state: 'allowMultipleVotes'
      });

      this.numberOfVotesRef = base.listenTo('currentGame/numberOfVotes', {
        context: this,
        state: 'numberOfVotes',
        then(numberOfVotes){
          let queries = {orderByChild: 'uid', equalTo: base64EncodedEmail}
          // if(typeof numberOfVotes === "number"){
          //   queries['limitToLast'] = numberOfVotes
          // }
          this.currentGameResponsesRef = base.listenTo('userAnswers', {
            context: this,
            asArray: true,
            queries: queries,
            then(currentGameResponses){
              if(currentGameResponses.length === 0 && this.props.variables && this.props.variables.collectDistance && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
                this.setState({
                  numberOfVotes: numberOfVotes,
                }, () => {
                  this.checkUsersLocation();
                })
              } else {
                this.setState({
                  numberOfVotes: numberOfVotes,
                  currentGameResponses: currentGameResponses,
                  locationChecking: false,
                  startAnimating: true
                })
              }
            }
          });
        }
      });

      this.sessionLengthRef = base.bindToState('currentGame/sessionLength', {
        context: this,
        state: 'sessionLength'
      });

      this.voteSessionRef = base.bindToState('currentGame/voteSession', {
        context: this,
        state: 'voteSession'
      });

      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string") {
            this.logUserActivity(key);
          }
        }
      });
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)
      this.props.loadPage();
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.questionsRef);
      base.removeBinding(this.gameActiveRef);
      base.removeBinding(this.allResponsesRef);
      if(this.currentGameResponsesRef){
        base.removeBinding(this.currentGameResponsesRef);
      }
      base.removeBinding(this.allowMultipleVotesRef);
      base.removeBinding(this.numberOfVotesRef);
      base.removeBinding(this.sessionLengthRef);
      base.removeBinding(this.voteSessionRef);
    }

    logout(){
      sessionStorage.removeItem('verifiedAge');
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('birthday');
      sessionStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        sessionStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        sessionStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables || {};
      const locationPermissions = sessionStorage.getItem('locationPermissions');
      if(!variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logUserActivity(currentGame){
      const vm = this;
      let userEmailToBase64 = "";
      if(currentGame && this.state.userEmail.indexOf('@') > -1){
        let gameid = currentGame;
        userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
      var userID = this.state.userEmail;
      if(userID.indexOf('@') > -1){
        userID = btoa(userID);
      }
      if(currentGame){
        base.post('currentGameVisitors/'+userID+"/", {
          data:userID,
          then(err){
            if(!err){
              console.log("currentVisitorLogged!")
            }
          }
        })
      }
    }

    collectNumberOfAnswersPerQuestion(responses, low_time=null){
      const userAnswersObject = {};
      for(const i in responses){
        const response = responses[i];
        if(low_time && response.timeStamp <= low_time){
          continue;
        }
        if(!userAnswersObject[response.questionId]){
          userAnswersObject[response.questionId] = response;
          userAnswersObject[response.questionId]["count"] = 1;
        } else {
          userAnswersObject[response.questionId]["count"]++;
        }
      }
      return userAnswersObject;
    }

    onClickAnswer(answerClicked, position, question, questionNumber){
      const gridItems = document.getElementsByClassName("grid-item");
      const tenantVariables = this.props.variables || {};
      const allowMultipleVotes = this.state.allowMultipleVotes;
      const numberOfVotesAllowed = this.state.numberOfVotes;
      const sessionLength = this.state.sessionLength;
      const voteSession = this.state.voteSession;
      const responses = this.state.currentGameResponses;
      const stringConstants = this.props.stringConstants || {};
      const element = gridItems[position];
      const answerInfo = {};
      const questions = this.state.questions || [];
      const questionsLength = questions.length;
      const timestamp = new Date().getTime();
      // document.getElement react-confirm-alert-button-group button
      const questionId = question.id || question.key;
      answerInfo['answerText'] = answerClicked.answerText;
      answerInfo['position'] = position;
      answerInfo['answerId'] = answerClicked.id;
      answerInfo['questionId'] = questionId;
      answerInfo['timeStamp'] = timestamp;
      answerInfo['uid'] = this.state.userEmail;
      if(this.state.userEmail.indexOf('@') > -1){
        answerInfo['uid'] = btoa(this.state.userEmail);
      }

      if(this.state.questionAnswered){
        confirmAlert({
            title: stringConstants.POLLINGOVERHEADER,
            variables: tenantVariables,
            message: stringConstants.NOMOREVOTESATTHISTIMETEXT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      let votesWithinTimeFrame = 0;
      let userAnswersObject;
      let low_time;
      const timeBoundResponses = voteSession && sessionLength;
      if(timeBoundResponses) {
        low_time = timestamp - sessionLength;
      }

      userAnswersObject = this.collectNumberOfAnswersPerQuestion(responses, low_time);
      let totalVotesCount = 0;
      for(const i in userAnswersObject){
        const objectWithVotes = userAnswersObject[i];
        totalVotesCount = totalVotesCount + objectWithVotes.count;
      }
      if(userAnswersObject[questionId]){
        votesWithinTimeFrame = userAnswersObject[questionId].count || 0;
      } else {
        votesWithinTimeFrame = 0;
      }

      if(!allowMultipleVotes && votesWithinTimeFrame > 0){
        confirmAlert({
            title: stringConstants.NOMOREPICKSTEXT,
            variables: tenantVariables,
            message: stringConstants.ONLYONEPICKPERFAN,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      if(allowMultipleVotes && numberOfVotesAllowed && numberOfVotesAllowed !== 0 && votesWithinTimeFrame >= parseInt(numberOfVotesAllowed)){
        confirmAlert({
            title: stringConstants.NOMOREPICKSTEXT,
            variables: tenantVariables,
            message: stringConstants.ONLYCERTAINNUMBEROFVOTESTEXTFIRST +" "+ numberOfVotesAllowed+" "+stringConstants.ONLYCERTAINNUMBEROFVOTESTEXTSECOND,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      const vm = this;
      base.push('userAnswers', {
        data: answerInfo,
        then(err)  {
          if(!err){
            // gridItems[position].classList.remove("animation", "bounce");
            element.classList.add("sheen");
            element.classList.add('start-now');
            setTimeout(function () {
              element.classList.remove("sheen");
              element.classList.remove('start-now')
              vm.afterAnswerPlayerPicked(numberOfVotesAllowed, questionsLength, timeBoundResponses, totalVotesCount, tenantVariables, votesWithinTimeFrame, responses, questionNumber, questions, stringConstants, allowMultipleVotes)
            }, 1000);
          }
        }
      });
    }

    afterAnswerPlayerPicked(numberOfVotesAllowed, questionsLength, timeBoundResponses, totalVotesCount, tenantVariables, votesWithinTimeFrame, responses, questionNumber, questions, stringConstants, allowMultipleVotes){
      console.log("Answer Picked!");
      let actualNumberOfVotesAllowed = numberOfVotesAllowed;
      let actualNumberOfVotesAllowedSingleQuestionLimit = numberOfVotesAllowed;
      if(!allowMultipleVotes){
        actualNumberOfVotesAllowedSingleQuestionLimit = 1;
      }
      actualNumberOfVotesAllowed = actualNumberOfVotesAllowedSingleQuestionLimit * questionsLength;
      if(totalVotesCount === actualNumberOfVotesAllowed - 1 && !timeBoundResponses){
        confirmAlert({
          title: tenantVariables.thanksForVotingHeader || "Thanks For Voting!",
          variables: tenantVariables,
          message: tenantVariables.thanksForVotingMessage,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        });
      } else if(votesWithinTimeFrame === actualNumberOfVotesAllowedSingleQuestionLimit - 1){
        let questionsAnsweredIdsList = [];
        for(const z in responses){
          const response = responses[z];
          questionsAnsweredIdsList.push(response.questionId);
        }
        let currentQuestionIndex = 0;
        for(const q in questions){
          const question = questions[q];
          if(questionsAnsweredIdsList.indexOf(question.id) === -1 && questionNumber !== currentQuestionIndex){
            this.setState({
              question:currentQuestionIndex
            })
            break;
          }
          currentQuestionIndex++;
        }
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const questionTextColor = tenantVariables.questionTextColor || "white";
      return(
        <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:questionTextColor}}>{this.props.stringConstants.NEXTGAMESTARTSTEXT} <br/><span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>{tenantVariables.playingText}</span></p>
        </>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.questionAnswered && !this.state.questionAnswered){
        this.removeReactAlert()
      }
    }

    navigateButtonClick(direction, numberOfQuestions){
      let question = this.state.question || 0;
      if(direction === "prev" && question > 0){
        question--;
      } else if(direction === "next" && question <= numberOfQuestions){
        question++;
      }
      this.setState({
        question: question,
        startAnimating: false
      }, () => {
        this.setState({
          startAnimating: true
        })
      })
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const responses = this.state.currentGameResponses;
      const secondaryColor = tenantVariables.secondaryColor || "#000";
      const primaryColor = tenantVariables.primaryColor || "#fff";
      const stringConstants = this.props.stringConstants || {};
      const userAnswers = this.state.userAnswers || [];
      const topLeftLogo = tenantVariables.topLeftImage || sampleAppTopImage;
      let totalTriviaAnswers = 0;
      let answerList = this.state.answerList;
      let show_percentages = false;
      if(tenantVariables.showFanPercentages === "all" || !tenantVariables.showScoreboardPercentages){
        show_percentages = true
      } else if (tenantVariables.showFanPercentages === "parttime" && this.state.questionAnswered) {
        show_percentages = true
      }
      let lastPickId;

      const questions = this.state.questions;
      const questionNumber = this.state.question || 0;
      let question = {};
      let questionId;
      if (questions.length > 0 && questionNumber < questions.length && this.state.questionAnswered === false) {
        question = questions[questionNumber];
        questionId = question.id || question.key;
        answerList = question.answers;
        // questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions);
        // videoURL = questions[questionNumber].videoLink;
      }

      if(userAnswers.length > 0){
        const tempCount = {};
        for(const answerIndex in userAnswers){
          const answerId = userAnswers[answerIndex].answerId;
          if(userAnswers[answerIndex].questionId === questionId){
            totalTriviaAnswers++;
            if(tempCount[answerId]){
              tempCount[answerId]['count'] = tempCount[answerId]['count'] + 1;
            } else {
              tempCount[answerId] = {};
              tempCount[answerId]['count'] = 1;
              tempCount[answerId]['answerText'] = userAnswers[answerIndex].answerText;
            }
          }
        }

        if(responses.length > 0){
          for(const responseIndex in responses){
            const response = responses[responseIndex];
            if(response.questionId === questionId){
              lastPickId = response.answerId;
            }
          }
        }

        for(const add_answer in answerList){
          if(tempCount[answerList[add_answer].id]){
            answerList[add_answer]['count'] = tempCount[answerList[add_answer].id].count
          } else {
            answerList[add_answer]['count'] = 0
          }
        }
      }

      return(
            <div className="container-fluid">
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftLogo} alt="" style={{marginBottom:"0"}}/>
              </div>
              {this.state.startAnimating && this.state.questionAnswered === false &&
                  <PoseGroup animateOnMount={true} delta={1} style={{margin: "auto", width: "100%"}}>
                    <Trans key={233}>
                      <div className="row top-text" style={{justifyContent: "center", maxWidth: 800}}>
                        <p className="top-text-header" style={{fontWeight: 700, color: tenantVariables.secondaryColor, display: this.state.questionAnswered ? 'block' : 'none'}}>{stringConstants.FINALRESULTTEXT}</p>
                        <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight: 700, color: tenantVariables.userQuestionColor}}>{question.questionText}</p>
                      </div>
                      <div className="row" style={{marginBottom: questions.length > 0 ? 220 : 20}}>
                        <AnswerItemsComponent lastPickId={lastPickId} show_percentages={show_percentages} gameover={this.state.questionAnswered} totalCount={totalTriviaAnswers} question={question} answers={answerList} tenantVariables={tenantVariables} onClickAnswer={(item, i) => {
                          this.onClickAnswer(item, i, question, questionNumber)
                        }}/>
                      </div>
                    </Trans>
                  </PoseGroup>
              }
              {questions && questions.length > 1 &&
                  <div className="bottom_row_fixed" style={{backgroundColor: "black"}}>
                    <button onClick={()=>this.navigateButtonClick("prev", questions.length)} className="btn btn-info" style={{backgroundColor: primaryColor, color: secondaryColor, visibility: questionNumber === 0 ? "hidden": "", float: "left", height:90, width:90, fontSize: "1.8em", margin:5, border: "none", fontWeight: 800}}>Prev</button>
                    <button onClick={()=>this.navigateButtonClick("next", questions.length)} className="btn btn-info" style={{backgroundColor: primaryColor, color: secondaryColor, float: "right", visibility: questionNumber +1 >= questions.length ? "hidden": "", height:90, width:90, fontSize: "1.8em", margin: 5, border: "none", fontWeight: 800}}>Next</button>
                  </div>
              }
              <div className="mobilehide" style={{height:"100px", width:"1px"}}/>
          </div>
        )
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const turnOffLoginButton = process.env.REACT_APP_FIREBASE_PROJECT_ID === "bearslivepoll";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      const link = tenantRules.rulesAndRegsLink;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        let redirectString = "/login";
        let langague = "";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        let urlToGo = "/age_gate";
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 1){
          langauge += splitWindowLocation[1];
          urlToGo += langauge
        }
        return (
            <Redirect to={urlToGo} />
        )
      }
      let renderMainScreen = this.renderHoldingScreen();
      if(this.state.gameActive && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen();
      }
      let maxWidthAllowed = 300;
      const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
      if(bodyWidth > 0 && bodyWidth < 300){
        maxWidthAllowed = bodyWidth;
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{display: isMlbApp? "block":"none", textAlign: "left"}}>
                      <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Support</button>
                    </div>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden", marginLeft: isMlbApp && "auto"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div style={{display: (isMlbApp || this.props.passedEmail || turnOffLoginButton) && "none"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.winnerModal} id="myOtherModal">
              <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                <span className="unselectable">Hold your finger on the box to save this coupon to your photos<br/>👇👇👇👇👇👇</span>
                <div className="unselectable" style={{height:10, width: "100%"}}/>
                <center id="downloadModalId"/>
              </center>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
